import { send } from "emailjs-com";
import { useState } from "react";
import { Button } from "react-bootstrap";
import style from "./Contacts.module.scss";
import cn from "classnames";

export interface ContactsProps {
  setShowToast: () => void;
}
const Contacts: React.FC<ContactsProps> = ({ setShowToast }) => {
  const [toSend, setToSend] = useState({
    from_name: "",
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    setShowToast();
    if (toSend.from_name !== "") {
      send("service_jtwz5lq", "template_pdfef0c", toSend, "Y2X-wMZ6r3c8l5TZS")
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
        })
        .catch((err) => {
          console.log("FAILED...", err);
        });
    }
  };
  const handleChange = (e: any) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div id="contacts" className={style.contacts}>
      <div className={style.content}>
        <h1>Получить бесплатную консультацию</h1>
        <h2>+7 953 841-17-05</h2>
        <p>Напишите свой номер телефона, мы перезвоним</p>
        <form className={style.form}>
          <div className="input-group">
            <span className={cn("input-group-text", style.text)} id="inputGroupPrepend2">
              Номер телефона:
            </span>
            <input
              pattern="[0-9]{7,10}"
              id="validationDefaultUsername"
              aria-describedby="inputGroupPrepend2"
              required
              type="tel"
              placeholder="Введите номер"
              name="from_name"
              className={cn("form-control", style.input)}
              value={toSend.from_name}
              onChange={handleChange}
            />
          </div>
          <Button type="submit" className={style.button} onClick={onSubmit}>
            Отправить
          </Button>
        </form>

        {/* <InputGroup className="d-block d-md-flex" size="lg">
          <InputGroup.Text className={style.text} id="inputGroup-sizing-lg">
            Номер телефона:
          </InputGroup.Text>
          <Form.Control type="tel" placeholder="Введите номер" name="from_name" className={style.input} value={toSend.from_name} onChange={handleChange} />
          <Button onClick={onSubmit}>Отправить</Button>
        </InputGroup> */}
      </div>
    </div>
  );
};
export default Contacts;
