import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import style from "./About.module.scss";
import cn from "classnames";

export interface AboutItemProps {
  id: number;
  title: string;
  img: string;
  subtitle1: string;
  subtitle2: string;
}

const AboutItem: React.FC<AboutItemProps> = ({ id, title, subtitle1, subtitle2 }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const options = {
    root: null,
    rootMargin: "50px",
    threshold: 0,
  };

  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;

    if (entry.isIntersecting) {
      setVisible(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);
  return (
    <>
      <Row
        ref={ref}
        className={cn("animate__animated", style.row, style.gradient_border, id % 2 === 0 && visible ? "animate__fadeInLeftBig" : "", id % 2 === 1 && visible ? "animate__fadeInRightBig" : "")}
      >
        <h3>{title}</h3>
        {subtitle1 && (
          <>
            <p className="m-0">{subtitle1}</p>
            <p>{subtitle2}</p>
          </>
        )}
      </Row>
    </>
  );
};
export default AboutItem;
