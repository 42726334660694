import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";

import App from "./App";
import React from "react";

const rootElem = document.getElementById("root");

if (rootElem) {
  const root = ReactDOM.createRoot(rootElem);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
