import React from "react";
import { SwishSpinner } from "react-spinners-kit";
import style from "./Loader.module.scss";

const Loader: React.FC = () => {
  return (
    <>
      <div className={style.loader}>
        <SwishSpinner size={100} color="#000" frontColor="#C5DDE8" backColor="#EECFBA" loading={true} />
      </div>
    </>
  );
};

export default Loader;
