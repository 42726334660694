import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Main from "../components/Main/Main";

const SuccessPay: React.FC = () => {
  return (
    <>
      <div className="wraper" style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flex: "0 0 auto" }}>
          <Header />
        </div>
        <div style={{ flex: "1 0 auto" }}>
          <Main />
        </div>
        <div style={{ flex: "0 0 auto" }}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SuccessPay;
