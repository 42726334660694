import { useState } from "react";
import { Toast } from "react-bootstrap";
import About from "./About/About";
import Advantages from "./Advantages/Advantages";
import Contacts from "./Contacts/Contacts";
import PriceList from "./PriceList/PriceList";
import style from "./Main.module.scss";

const Main: React.FC = () => {
  const [show, setShow] = useState(false);
  return (
    <div className={style.main}>
      <About />
      <PriceList />
      <Advantages />
      <Contacts
        setShowToast={() => {
          setShow(true);
        }}
      />
      <Toast className={style.toast} onClose={() => setShow(false)} show={show} delay={4000} autohide>
        <Toast.Header>
          <strong className="me-auto">ГНБ Ужур</strong>
        </Toast.Header>
        <Toast.Body>Ваш запрос на звонок отправлен, ожидайте...</Toast.Body>
      </Toast>
    </div>
  );
};
export default Main;
