import React, { Suspense } from "react";
import Loader from "./components/Loader/Loader";
import MainPage from "./pages/MainPage";

// const MainPage = React.lazy(() => import("./pages/MainPage"));

function App() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <MainPage />
      </Suspense>
    </>
  );
}

export default App;
