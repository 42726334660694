import { Container, Nav, Navbar } from "react-bootstrap";
import style from "./Header.module.scss";
import cn from "classnames";

const Header: React.FC = () => {
  return (
    <div className={style.header}>
      <img src={"/img/horizontalwell1920х1080.webp"} style={{ position: "absolute", height: "100vh", width: "100%", objectFit: "cover" }} alt="" />

      <Navbar className={cn("pop-up", style.nav)} collapseOnSelect={true} expand="lg">
        <Container fluid>
          <h4 className="me-4">ГНБ Краснояркий край</h4>

          <Navbar.Toggle style={{ color: "white" }} />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link className={style.underline} href="#about">
                О нас
              </Nav.Link>
              <Nav.Link className={style.underline} href="#pricelist">
                Услуги и цены
              </Nav.Link>

              <Nav.Link className={style.underline} href="#advantages">
                Преимущества
              </Nav.Link>
              <Nav.Link className={style.underline} href="#contacts">
                Контакты
              </Nav.Link>
            </Nav>
            <div className={style.contacts}>
              <a className="d-block" href={"tel: +7 953 841-17-05"}>
                +7 953 841-17-05
              </a>
              <a href="mailto: order@hordrill.ru">E-mail: order@hordrill.ru</a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className={cn("text-center", style.slogan)}>
        <h1 className="mb-4">Горизонтальное бурение ГНБ в Красноярском крае</h1>
        <h4>Горизонтально-направленное бурение – один из наиболее современных методов прокладки инженерных коммуникаций.</h4>
      </Container>
    </div>
  );
};
export default Header;
