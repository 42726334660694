import { Container } from "react-bootstrap";
import style from "./PriceList.module.scss";

const PriceList: React.FC = () => {
  return (
    <>
      <Container id="pricelist" className={style.price_list}>
        <h1>Стоимость работ ГНБ</h1>

        <table className="w-100 text-center" style={{ borderRadius: "20px" }}>
          <thead className="" style={{ borderRadius: "20px" }}>
            <tr className="" style={{ background: "linear-gradient(45deg, #c5e8de, #d7baee)", borderRadius: "20px" }}>
              <th className=" w-50 py-3">Диаметр трубы</th>
              <th className=" w-50 py-3">Цена за 1 м.п.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className=" w-50  py-3">25-1800 мм</td>
              <td className=" w-50 py-3">По договоренности от 1200 р.</td>
            </tr>
          </tbody>
        </table>
      </Container>
    </>
  );
};
export default PriceList;
