import style from "./Advantages.module.scss";
import { Row, Col } from "react-bootstrap";

const advdata = [
  { id: 1, img: "/img/tractor.svg", title: "Свой парк техники", subtitle: "Большой парк различной техники, инструмента, оборудования" },
  { id: 2, img: "/img/key.svg", title: "Инженерные сети под ключ", subtitle: "От проекта до ввода в эксплуатацию" },
  { id: 3, img: "/img/ruble.svg", title: "Выгодные цены", subtitle: "Цены одни из самых низких по области" },
  { id: 4, img: "/img/star.svg", title: "Опыт и профессионализм", subtitle: "Более 4000 проколов успешно пробурено нашими специалистами" },
  { id: 5, img: "/img/minecraft.svg", title: "Любые категории грунтов", subtitle: "Бурим в любых грунтах, в том числе скальную породу" },
  { id: 6, img: "/img/car.svg", title: "Бесплатный выезд", subtitle: "Бесплатная консультация и выезд специалиста на объект" },
];

const Advantages: React.FC = () => {
  return (
    <div id="advantages" className={style.advantages}>
      <div className={style.content}>
        <h1 className="mb-5">Наши преимущества</h1>
        <Row xs={1} sm={2} md={2} lg={3} xl={3}>
          {advdata.map((item) => (
            <Col className="text-center mb-5" key={item.id}>
              <img className="mb-3" src={item.img} alt={item.img} />
              <h3>{item.title}</h3>
              <p>{item.subtitle}</p>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default Advantages;
