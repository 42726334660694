import style from "./Footer.module.scss";

function Footer() {
  return (
    <div className={style.footer}>
      <div className={style.link_wrap}>
        <a className={style.link} href="#about">
          О нас
        </a>

        <a className={style.link} href="#pricelist">
          Услуги и цены
        </a>

        <a className={style.link} href="#advantages">
          Преимущества
        </a>

        <a className={style.link} href="#contacts">
          Контакты
        </a>
      </div>
      <br />
      <br />
      <div className={style.contacts}>
        <a href={"tel: +7 953 841-17-05"}>+7 953 841-17-05</a>
        <br />
        <a href="mailto: order@hordrill.ru">E-mail: order@hordrill.ru</a>
      </div>
      <div>
        <p style={{ position: "absolute", color: "transparent", cursor: "default", zIndex: "-1000" }}>☺</p>
      </div>
    </div>
  );
}
export default Footer;
