import { Carousel, Container } from "react-bootstrap";
import style from "./About.module.scss";
import AboutItem from "./AboutItem";

const aboutdata = [
  {
    id: 1,
    img: "",
    title: "Бестраншейная и траншейная прокладка коммуникаций:",
    subtitle1: "Проколы под воду, канализацию, газ, футляры под электрические кабели и оптоволокно",
    subtitle2: "Открытый траншейный способ прокладки коммуникаций",
  },
  { id: 2, img: "", title: "Монтаж и обвязка колодцев, камер, КНС, септиков", subtitle1: "", subtitle2: "" },
  { id: 3, img: "", title: "Сварка труб стыковая и муфтовая. Аварийно-восстановительные работы", subtitle1: "", subtitle2: "" },
  { id: 4, img: "", title: "Проектирование", subtitle1: "", subtitle2: "" },
  { id: 5, img: "", title: "Подготовка и согласование необходимой документации, сдача построенных объектов", subtitle1: "", subtitle2: "" },
  { id: 6, img: "", title: "В процессе работы мы применяем собственную спец. технику — ГНБ XCMG XZ200", subtitle1: "", subtitle2: "" },
];
const technic = [
  { id: 1, img: "/img/1.webp", capture: "" },
  { id: 2, img: "/img/2.webp", capture: "" },
  { id: 4, img: "/img/4.webp", capture: "" },
  { id: 5, img: "/img/5.webp", capture: "" },
];

const About: React.FC = () => {
  return (
    <div id="about" className={style.about}>
      <Container className="pb-5">
        <h1>О НАС</h1>
        <h2 className="mb-4">Наша организация предоставляет следующие услуги:</h2>
        {aboutdata.map((item) => (
          <AboutItem key={item.id} {...item} />
        ))}
      </Container>
      <Carousel fade interval={5000} indicators={false} className={style.carouselka}>
        {technic.map((item) => (
          <Carousel.Item key={item.id}>
            <img className="d-block w-100" src={item.img} alt="First slide" />
            <Carousel.Caption>
              <div className={style.tractor_description}>
                <h3>ГНБ XCMG XZ200</h3>
                ГНБ XCMG XZ200 - это самоходная,компактная установка, в которой применяется гидравлическая система управления с помощью джойстиков, обеспечивающими комфорт и точность управления.
                Основные технические параметры и технология управления достигают европейского передового уровня CE. <br />
                <br /> Остались вопросы? <a href="#contacts">Свяжитесь с нами</a> прямо сейчас и получите бесплатную консультацию.
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
export default About;
